<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!--<a-col :md="6" :sm="24">-->
              <!--<a-form-item :label="$t('抽奖活动中奖记录.抽奖活动id')" prop="luckActivityId">-->
                <!--<a-input v-model="queryParam.luckActivityId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.抽奖活动id')" allow-clear/>-->
              <!--</a-form-item>-->
            <!--</a-col>-->
            <!--<a-col :md="6" :sm="24">-->
              <!--<a-form-item :label="$t('抽奖活动中奖记录.中奖信息文案')" prop="name">-->
                <!--<a-input v-model="queryParam.name" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.中奖信息文案')" allow-clear/>-->
              <!--</a-form-item>-->
            <!--</a-col>-->

            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('抽奖活动中奖记录.抽奖人昵称')" prop="nickName">
                <a-input v-model="queryParam.nickName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.抽奖人昵称')" allow-clear/>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('抽奖活动中奖记录.奖励类型')" prop="type">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('抽奖活动中奖记录.奖励类型')" v-model="queryParam.type" allowClear>
                  <a-select-option  :value="1">{{$t('奖励类型.谢谢惠顾')}}</a-select-option>
                  <a-select-option  :value="2">{{$t('奖励类型.平台币')}}</a-select-option>
                  <a-select-option  :value="3">{{$t('奖励类型.商品')}}</a-select-option>
                  <a-select-option  :value="4">{{$t('奖励类型.优惠券')}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('抽奖活动中奖记录.邮寄状态')" prop="type">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('抽奖活动中奖记录.邮寄状态')" v-model="queryParam.mailStatus" allowClear>
                  <a-select-option  :value="1">{{$t('邮寄状态.待申请邮寄')}}</a-select-option>
                  <a-select-option  :value="2">{{$t('邮寄状态.待邮寄')}}</a-select-option>
                  <a-select-option  :value="3">{{$t('邮寄状态.已邮寄')}}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <!--<template v-if="advanced">-->
              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('抽奖活动中奖记录.商品id')" prop="goodsId">-->
                  <!--<a-input v-model="queryParam.goodsId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.商品id')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('抽奖活动中奖记录.商品名称')" prop="goodsName">-->
                  <!--<a-input v-model="queryParam.goodsName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.商品名称')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('抽奖活动中奖记录.商品图片')" prop="goodsPictire">-->
                  <!--<a-input v-model="queryParam.goodsPictire" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.商品图片')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('抽奖活动中奖记录.申请邮寄地址')" prop="addressId">-->
                  <!--<a-input v-model="queryParam.addressId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.申请邮寄地址')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('抽奖活动中奖记录.姓名')" prop="realName">-->
                  <!--<a-input v-model="queryParam.realName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.姓名')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('抽奖活动中奖记录.手机号')" prop="mobile">-->
                  <!--<a-input v-model="queryParam.mobile" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.手机号')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('抽奖活动中奖记录.详细地址')" prop="detailedAddress">-->
                  <!--<a-input v-model="queryParam.detailedAddress" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.详细地址')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('抽奖活动中奖记录.物流公司id')" prop="logisticsId">-->
                  <!--<a-input v-model="queryParam.logisticsId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.物流公司id')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('抽奖活动中奖记录.物流公司名称')" prop="logisticsName">-->
                  <!--<a-input v-model="queryParam.logisticsName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.物流公司名称')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->

              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('抽奖活动中奖记录.寄件人手机号,')" prop="deliveryMobile">-->
                  <!--<a-input v-model="queryParam.deliveryMobile" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.寄件人手机号,')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('抽奖活动中奖记录.物流编号')" prop="logisticsNo">-->
                  <!--<a-input v-model="queryParam.logisticsNo" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.物流编号')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->

              <!--<a-col :md="6" :sm="24">-->
                <!--<a-form-item :label="$t('抽奖活动中奖记录.优惠券id')" prop="couponTypeId">-->
                  <!--<a-input v-model="queryParam.couponTypeId" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.优惠券id')" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
            <!--</template>-->
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
                <!--<a @click="toggleAdvanced" style="margin-left: 8px">-->
                  <!--{{ advanced ? $t('通用.按钮.收起') : $t('通用.按钮.展开') }}-->
                  <!--<a-icon :type="advanced ? 'up' : 'down'"/>-->
                <!--</a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--<a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['luckActivityRecord:luckActivityRecord:add']">-->
          <!--<a-icon type="plus" />{{$t('通用.按钮.新增')}}-->
        <!--</a-button>-->
        <!--<a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['luckActivityRecord:luckActivityRecord:edit']">-->
          <!--<a-icon type="edit" />{{$t('通用.按钮.修改')}}-->
        <!--</a-button>-->
        <!--<a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['luckActivityRecord:luckActivityRecord:remove']">-->
          <!--<a-icon type="delete" />{{$t('通用.按钮.删除')}}-->
        <!--</a-button>-->
        <a-button type="primary" @click="handleExport" v-hasPermi="['luckActivityRecord:luckActivityRecord:export']">
          <a-icon type="download" />{{$t('通用.按钮.导出')}}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />

      <luck-delivery-form
        ref="luckDeliveryForm"
        @ok="getList"
      />

      <luck-logistic-detail
        ref="luckLogisticDetail"
      />

      <luck-detail-form
        ref="luckDetailForm"
        @ok="getList"
      />

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >

         <span slot="type" slot-scope="text, record">
           <!--<custom-dict-tag :options="customDict.SECKILLENUM" :value="record.checkStatus"/>-->
           <span v-if="record.type == '1'">
             {{$t('奖励类型.谢谢惠顾')}}
           </span>
           <span v-if="record.type == '2'">
             {{$t('奖励类型.平台币')}}
           </span>
           <span v-if="record.type == '3'">
             {{$t('奖励类型.商品')}}
           </span>
            <span v-if="record.type == '4'">
             {{$t('奖励类型.优惠券')}}
           </span>
        </span>

        <span slot="mailStatus" slot-scope="text, record">
           <span v-if="record.mailStatus == '1'">
             {{$t('邮寄状态.待申请邮寄')}}
           </span>
           <span v-if="record.mailStatus == '2'">
             {{$t('邮寄状态.待邮寄')}}
           </span>
           <span v-if="record.mailStatus == '3'">
             {{$t('邮寄状态.已邮寄')}}
           </span>
        </span>


        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <!--<a-divider type="vertical" v-hasPermi="['luckActivityRecord:luckActivityRecord:edit']" />-->
          <!--<a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['luckActivityRecord:luckActivityRecord:edit']">-->
             <!--<a-icon type="edit" />{{$t('通用.按钮.修改')}}-->
          <!--</a>-->
          <!--<a-divider type="vertical" v-hasPermi="['luckActivityRecord:luckActivityRecord:remove']" />-->
          <!--<a @click="handleDelete(record)" v-hasPermi="['luckActivityRecord:luckActivityRecord:remove']">-->
            <!--<a-icon type="delete" />{{$t('通用.按钮.删除')}}-->
          <!--</a>-->


          <a @click="$refs.luckDetailForm.handleDetail(record.id)">
            <a-icon type="eye" />{{$t('通用.按钮.详情')}}
          </a>
          <span v-if="record.type===3  && record.mailStatus!=1">
            <a-divider type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link"  @click="e => e.preventDefault()">
                <a-icon type="double-right" />
                {{$t('通用.按钮.更多')}}
              </a>
              <a-menu slot="overlay">
                <a-menu-item v-if="record.type===3 && record.mailStatus===2">
                  <a @click="$refs.luckDeliveryForm.handleDelivery(record.id)">
                    <a-icon type="edit" />{{$t('销售订单.发货')}}
                  </a>
                </a-menu-item>
                 <a-menu-item v-if="record.type===3 && record.mailStatus===3">
                  <a @click="$refs.luckLogisticDetail.handleDetail(record.id)">
                    <a-icon type="environment" />{{$t('销售订单.物流详情')}}
                  </a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>



        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageLuckActivityRecord,listLuckActivityRecord, delLuckActivityRecord } from '@/api/luckActivityRecord/luckActivityRecord'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import LuckDeliveryForm from "@/views/luckActivityRecord/luckActivityRecord/modules/LuckDeliveryForm";
import LuckLogisticDetail from "@/views/luckActivityRecord/luckActivityRecord/modules/LuckLogisticDetail";
import LuckDetailForm from "@/views/luckActivityRecord/luckActivityRecord/modules/LuckDetailForm";


export default {
  name: 'LuckActivityRecord',
  components: {
    LuckDeliveryForm,
    LuckLogisticDetail,
    LuckDetailForm,
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        luckActivityId: undefined,
        name: undefined,
        type: undefined,
        gold: undefined,
        goodsId: undefined,
        goodsName: undefined,
        goodsPictire: undefined,
        mailStatus: undefined,
        addressId: undefined,
        realName: undefined,
        mobile: undefined,
        deliveryType: undefined,
        detailedAddress: undefined,
        logisticsId: undefined,
        logisticsName: undefined,
        deliveryMobile: undefined,
        logisticsNo: undefined,
        couponTypeId: undefined,
        nickName: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: this.$t('抽奖活动中奖记录.id'),
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.抽奖活动id'),
        //   dataIndex: 'luckActivityId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.中奖信息文案'),
        //   dataIndex: 'name',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('抽奖活动中奖记录.奖励类型'),
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'type'},
        },
        {
          title: this.$t('抽奖活动中奖记录.抽奖人昵称'),
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center',
        },
        {
          title: this.$t('抽奖活动中奖记录.抽奖人手机号'),
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center',
        },
        {
          title: this.$t('抽奖活动中奖记录.奖励金额'),
          dataIndex: 'gold',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('抽奖活动中奖记录.商品名称'),
          dataIndex: 'goodsName',
          ellipsis: true,
          align: 'center',
        },
        {
          title: this.$t('抽奖活动中奖记录.邮寄状态'),
          dataIndex: 'mailStatus',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'mailStatus'},
        },
        {
          title: this.$t('抽奖活动中奖记录.优惠券名称'),
          dataIndex: 'couponName',
          ellipsis: true,
          align: 'center',
        },
        // {
        //   title: this.$t('抽奖活动中奖记录.商品id'),
        //   dataIndex: 'goodsId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.商品名称'),
        //   dataIndex: 'goodsName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.商品图片'),
        //   dataIndex: 'goodsPictire',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.邮寄状态 1:待申请邮寄 2:待邮寄 3:已邮寄'),
        //   dataIndex: 'mailStatus',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.申请邮寄地址'),
        //   dataIndex: 'addressId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.姓名'),
        //   dataIndex: 'realName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.手机号'),
        //   dataIndex: 'mobile',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.发货方式'),
        //   dataIndex: 'deliveryType',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.详细地址'),
        //   dataIndex: 'detailedAddress',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.物流公司id'),
        //   dataIndex: 'logisticsId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.物流公司名称'),
        //   dataIndex: 'logisticsName',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.寄件人手机号'),
        //   dataIndex: 'deliveryMobile',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.物流编号'),
        //   dataIndex: 'logisticsNo',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.优惠券id'),
        //   dataIndex: 'couponTypeId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.创建时间'),
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: this.$t('抽奖活动中奖记录.备注'),
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询抽奖活动中奖记录列表 */
    getList () {
      this.loading = true
     pageLuckActivityRecord(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        luckActivityId: undefined,
        name: undefined,
        type: undefined,
        gold: undefined,
        goodsId: undefined,
        goodsName: undefined,
        goodsPictire: undefined,
        mailStatus: undefined,
        addressId: undefined,
        realName: undefined,
        mobile: undefined,
        deliveryType: undefined,
        detailedAddress: undefined,
        logisticsId: undefined,
        logisticsName: undefined,
        deliveryMobile: undefined,
        logisticsNo: undefined,
        couponTypeId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField==='createTime') {
          this.queryParam.sortField= 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delLuckActivityRecord(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              const message = that.$t('通用.文本.删除成功')
              that.$message.success(
                message,
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('luckActivityRecord/luckActivityRecord/export',
            ...that.queryParam
          , `抽奖活动中奖记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

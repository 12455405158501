<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="luckActivityId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.抽奖活动id')}}<template slot="title">{{$t('抽奖活动中奖记录.抽奖活动id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.luckActivityId"  :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.抽奖活动id')" />
      </a-form-model-item>
      <a-form-model-item prop="name" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.中奖信息文案')}}<template slot="title">{{$t('抽奖活动中奖记录.中奖信息文案')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.name" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.中奖信息文案')" />
      </a-form-model-item>
      <a-form-model-item prop="type" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.奖励类型 1:谢谢惠顾 2:平台币 3:商品 4:优惠券')}}<template slot="title">{{$t('抽奖活动中奖记录.奖励类型 1:谢谢惠顾 2:平台币 3:商品 4:优惠券')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="gold" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.奖励金额')}}<template slot="title">{{$t('抽奖活动中奖记录.奖励金额')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.gold"  :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.奖励金额')" />
      </a-form-model-item>
      <a-form-model-item prop="goodsId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.商品id')}}<template slot="title">{{$t('抽奖活动中奖记录.商品id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.goodsId"  :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.商品id')" />
      </a-form-model-item>
      <a-form-model-item prop="goodsName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.商品名称')}}<template slot="title">{{$t('抽奖活动中奖记录.商品名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.goodsName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.商品名称')" />
      </a-form-model-item>
      <a-form-model-item prop="goodsPictire" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.商品图片')}}<template slot="title">{{$t('抽奖活动中奖记录.商品图片')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.goodsPictire" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.商品图片')" />
      </a-form-model-item>
      <a-form-model-item prop="mailStatus" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.邮寄状态 1:待申请邮寄 2:待邮寄 3:已邮寄')}}<template slot="title">{{$t('抽奖活动中奖记录.邮寄状态 1:待申请邮寄 2:待邮寄 3:已邮寄')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="addressId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.申请邮寄地址')}}<template slot="title">{{$t('抽奖活动中奖记录.申请邮寄地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.addressId"  :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.申请邮寄地址')" />
      </a-form-model-item>
      <a-form-model-item prop="realName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.姓名')}}<template slot="title">{{$t('抽奖活动中奖记录.姓名')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.realName" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.姓名')" />
      </a-form-model-item>
      <a-form-model-item prop="mobile" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.手机号')}}<template slot="title">{{$t('抽奖活动中奖记录.手机号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.mobile" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.手机号')" />
      </a-form-model-item>
      <a-form-model-item prop="deliveryType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.发货方式')}}<template slot="title">{{$t('抽奖活动中奖记录.发货方式')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="detailedAddress" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.详细地址')}}<template slot="title">{{$t('抽奖活动中奖记录.详细地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.detailedAddress" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.详细地址')" />
      </a-form-model-item>
      <a-form-model-item prop="logisticsId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.物流公司id')}}<template slot="title">{{$t('抽奖活动中奖记录.物流公司id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.logisticsId"  :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.物流公司id')" />
      </a-form-model-item>
      <a-form-model-item prop="logisticsName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.物流公司名称')}}<template slot="title">{{$t('抽奖活动中奖记录.物流公司名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.logisticsName" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.物流公司名称')" />
      </a-form-model-item>
      <a-form-model-item prop="deliveryMobile" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.寄件人手机号,')}}<template slot="title">{{$t('抽奖活动中奖记录.寄件人手机号,')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.deliveryMobile" :maxLength="32" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.寄件人手机号,')" />
      </a-form-model-item>
      <a-form-model-item prop="logisticsNo" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.物流编号')}}<template slot="title">{{$t('抽奖活动中奖记录.物流编号')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.logisticsNo" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.物流编号')" />
      </a-form-model-item>
      <a-form-model-item prop="couponTypeId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.优惠券id')}}<template slot="title">{{$t('抽奖活动中奖记录.优惠券id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.couponTypeId"  :placeholder="$t('通用.输入.请输入')+$t('抽奖活动中奖记录.优惠券id')" />
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('抽奖活动中奖记录.备注')}}<template slot="title">{{$t('抽奖活动中奖记录.备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLuckActivityRecord, addLuckActivityRecord, updateLuckActivityRecord } from '@/api/luckActivityRecord/luckActivityRecord'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        luckActivityId: null,

        name: null,

        type: null,

        gold: null,

        goodsId: null,

        goodsName: null,

        goodsPictire: null,

        mailStatus: 0,

        addressId: null,

        realName: null,

        mobile: null,

        deliveryType: null,

        detailedAddress: null,

        logisticsId: null,

        logisticsName: null,

        deliveryMobile: null,

        logisticsNo: null,

        couponTypeId: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        luckActivityId: [
          { required: true, message: this.$t('抽奖活动中奖记录.抽奖活动id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('抽奖活动中奖记录.中奖信息文案')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        type: [
          { required: true, message: this.$t('抽奖活动中奖记录.奖励类型 1:谢谢惠顾 2:平台币 3:商品 4:优惠券')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        deliveryType: [
          { required: true, message: this.$t('抽奖活动中奖记录.发货方式')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        luckActivityId: null,
        name: null,
        type: null,
        gold: null,
        goodsId: null,
        goodsName: null,
        goodsPictire: null,
        mailStatus: 0,
        addressId: null,
        realName: null,
        mobile: null,
        deliveryType: null,
        detailedAddress: null,
        logisticsId: null,
        logisticsName: null,
        deliveryMobile: null,
        logisticsNo: null,
        couponTypeId: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLuckActivityRecord({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateLuckActivityRecord(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addLuckActivityRecord(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
